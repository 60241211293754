/* fonts
    font-family: 'Nunito', sans-serif;
    font-family: 'Gloria Hallelujah', cursive;
    font-family: 'Caveat', cursive;
    font-family: 'Handlee', cursive;
    font-family: 'Marck Script', cursive;

// colors
    

*/

.rsc-blue {
    color: var(--main-blue) !important;
}

.rsc-green {
    color: var(--main-green) !important;
}

.rsc-rose {
    color: rgb(175, 91, 119) !important;
}

.rsc-grey {
    color: rgb(66, 66, 66) !important;
}

html,
body {
    height: 100vh !important;
    overflow: hidden;
    font-family: 'Nunito', sans-serif;
    background-color: var(--main-bg-color);
    color: var(--main-fg-color);
    max-width: 1fr;
}

#root {
    height: 100% !important;
    background-color: var(--main-bg-color);
    color: var(--main-fg-color);
    max-width: 1fr;
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-bg-color: rgb(46, 46, 46);
        --second-bg-color: rgb(31, 31, 31);
        --third-bg-color: rgb(66, 76, 85);
        --main-fg-color: rgb(214, 214, 214);
        --second-fg-color: rgb(172, 172, 172);
        --light-shadow-color: rgba(12, 12, 12, 0.959);
        --dark-shadow-color: rgba(0, 0, 0, 0.74);
        --main-blue: #46a4c9;
        --main-green: #0f9e59;
    }
}

@media (prefers-color-scheme: light) {
    :root {
        --main-bg-color: white;
        --second-bg-color: rgb(207, 207, 207);
        --third-bg-color: white;
        --main-fg-color: black;
        --second-fg-color: rgb(22, 22, 22);
        --light-shadow-color: rgba(136, 136, 136, 0.89);
        --dark-shadow-color: rgba(39, 39, 39, 0.658);
        --main-blue: #2472D7;
        --main-green: #078F4D;
    }
}



/*________________________________________________________________________________________________________________________ ________________________________________________________________________________________________________________________ */

.rsc-appWrapperHome {
    display: grid;
    height: 100% !important;
    grid-template-areas: 'nav''slide''seperator''main''social-links''footer';
    grid-template-rows: 100px 50px auto 1fr 100px auto;
    grid-template-columns: 99vw;
    overflow: auto;
    overflow-x: hidden;
    width: 100vw !important;
    max-width: 100vw !important;
}

/*_______________________________________________________NAVBAR___________________________________________________________*/

.rsc-navbar {
    background: var(--third-bg-color);
    width: 100vw !important;
    grid-area: nav;
    display: grid;
    grid-template-columns: 20% 63% 15%;
    grid-template-rows: 100%;
    grid-template-areas: 'logo1 navmenu logo2';
    font-size: 24px;
    padding: 0 !important;
    -webkit-box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
    -moz-box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
    box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
}

.rsc-navbar .text-logo {
    padding-left: 10px !important;
    padding-right: 10px !important;
    grid-area: logo;
    font-weight: bolder;
    font-size: 70px;
    font-family: 'Handlee', cursive;
    color: var(--main-blue) !important;
    box-shadow: none;
}

.nav-logo1 img {
    padding: 10px !important;
    grid-area: logo1;
    height: 100% !important;
    font-family: 'Handlee', cursive;
    color: var(--main-blue) !important;
    box-shadow: none;
}

.nav-logo2 img {
    padding-left: 40px !important;
    padding-right: 10px !important;
    margin-top: 10px;
    grid-area: logo2;
    width: 100% !important;
    font-family: 'Handlee', cursive;
    color: var(--main-blue) !important;
    box-shadow: none;
}

.rsc-navMenu {
    grid-area: navmenu;
    height: 100% !important;
    margin: auto;
    margin-top: 0;
}

.navbar-item {
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 0 30px 0 30px;
    margin: 0;
    background-color: var(--third-bg-color);
    z-index: 990;
    grid-area: navitem;
}

.navbar-item:hover {
    background-color: rgba(66, 66, 66, 0.041);
    transition-duration: 300ms;
}

.navbar-item-wrapper {
    display: grid;
    grid-template-areas: 'title''drowdown-icon';
    grid-template-rows: 45px 20px;
    grid-gap: 10px;
}

.navbar-item-title {
    grid-area: title;
    font-family: 'Marck Script', cursive;
    font-size: 55px;
    color: var(--main-blue);
}

.navbar-item-dropdown-icon {
    grid-area: drowdown-icon;
    font-family: Verdana, sans-serif;
    font-size: 30px;
    color: rgba(90, 88, 88, 0.726);
    text-align: center;
}

.navbar-item ul {
    display: none;
    position: absolute;
    top: 100%;
    min-width: 100%;
    width: max-content;
    padding: 0;
    margin-left: -30px;
    left: inherit;
    background-color: var(--third-bg-color);
    z-index: 999;
    list-style-type: none;
    border-top: 2px solid var(--main-blue);
}

/* Display Dropdowns on Hover */

.navbar-item:hover>ul {
    display: inherit;
    -webkit-box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
    -moz-box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
    box-shadow: 0px 1px 5px 0px var(--dark-shadow-color);
    border-top: 5px solid var(--main-blue);
}

/* Fisrt Tier Dropdown */

.navbar-item ul li {
    min-width: max-content;
    width: 100%;
    padding: 5px 10px 5px 10px;
    float: none;
    display: list-item;
    position: relative;
}

.navbar-item ul li:hover {
    background-color: var(--main-blue);
    color: var(--main-bg-color);
}

.navbar-item ul li {
    color: var(--main-fg-color);
}

.rsc-footer {
    grid-area: footer;
    display: grid;
    grid-template-areas: 'xcolumn';
    background-color: rgb(66, 66, 66);
    padding: 2% 20% 2% 20%;
    max-width: 100% !important;
}

.rsc-footer-column {
    display: grid;
    grid-template-areas: 'item';
    grid-template-columns: 100%;
    grid-auto-rows: 40px;
    text-align: center;
    padding: 0 0 0 0;
    color: rgb(153, 153, 153);
}

.rsc-footer-column p {
    margin-top: 3%;
}

.rsc-footer-column:nth-child(even) {
    border-left: 3px solid rgb(53, 52, 52);
    border-right: 3px solid rgb(53, 52, 52);
}

.rsc-footer-item {
    font-size: 18px;
    text-decoration: none;
    color: rgb(153, 153, 153);
}

.rsc-footer-item:hover {
    text-decoration: none;
    color: rgb(185, 185, 185);
}

.rsc-slideshow {
    grid-area: slide;
    overflow: hidden;
    height: 90vh !important;
    position: relative;
}

.butterCMS {
    color: rgb(153, 153, 153);
    position: absolute;
    top: 60px;
    right: 60px;
    width: 300px;
}

.butterCMS img {
    width: 250px;
}

.footer-wrapper {
    grid-area: footer;
    position: relative;
}

.video {
    height: 100%;
}

.copyrightEarth {
    position: absolute;
    z-index: 999;
    bottom: 5px;
    right: 5px;
    text-align: right;
    font-size: 40px;
    color: rgba(241, 241, 241, 0.911);
    text-shadow: 0px 0px 5px black;
}

.video-overlay {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    width: 100%;
    text-align: center;
    font-size: 60px;
    color: white;
    animation: fadeIn 2s infinite;
    animation-fill-mode: forwards;
    visibility: hidden;
    text-shadow: 0px 0px 8px black;
}

.video-overlay.titel {
    position: absolute;
    display: grid;
    grid-template-areas: 'item''item';
    bottom: 50%;
    right: 0;
    width: 20%;
    text-align: right;
    font-size: 60px;
    color: rgb(52, 97, 170);
    animation: none;
    visibility: visible;
    text-shadow: none;
}

.video-overlay.titel .text1 {
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 55%;
    font-size: 100px;
    background-color: var(--main-blue);
    padding: 0 10px 0 10px;
    padding: 2px;
    color: #fff;
    width: 320px;
    animation: einfliegen1 7s;
    animation-delay: 0s;
    font-weight: 600;
}

.video-overlay.titel .text2 {
    text-align: center;
    font-size: 40px;
    position: absolute;
    right: 0;
    background-color: var(--main-green);
    padding: 0 10px 0 10px;
    color: #fff;
    animation: einfliegen2 7s;
    animation-delay: 0s;
}

.news-box {
    grid-area: news-box;
}

.insta-box {
    grid-area: insta-box;
}

.rsc-subnav {
    grid-area: subnav;
}

.rsc-dropdownItem {
    font-size: 25px;
    font-family: 'Nunito', sans-serif;
}

.navbar-brand {
    height: 100px !important;
}

.navbar {
    padding-left: 0;
}

.sep {
    font-family: 'Marck Script', cursive;
    font-size: 60px !important;
    font-weight: 550;
    line-height: 60px;
    grid-column: 1 / span 3;
    grid-row: 1;
    color: var(--main-blue);
    text-align: center;
}

.rsc-content {
    grid-area: main;
    display: grid;
    grid-template-areas: 'sep sep sep''item item item''item item item''item item item';
    grid-template-columns: 400px 400px 400px;
    grid-auto-rows: auto;
    justify-content: center;
    grid-gap: 15px;
}

.iframe {
    grid-area: item;
    grid-column: 1 / 3;
}

.rsc-vorstand {
    grid-area: main;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: 600px;
}

.leeresPanel {
    grid-area: main;
    margin: 0 20% 5% 20%;
    height: max-content;
    padding: 1%;
}

.blogPanel {
    grid-area: main;
    margin: 0 20% 0% 20%;
    height: max-content;
}

.geschichtePanel {
    grid-area: main;
    display: grid;
    grid-template-columns: 60%;
    grid-template-areas: 'item''item''item''item''item''item';
    grid-auto-rows: auto;
    justify-content: center;
    grid-gap: 15px;
}

.kontaktinfos {
    list-style-type: none;
    margin: -35px;
    margin-top: 10px;
    min-height: 150px !important;
}

.card {
    margin: 1%;
}

.card:hover {
    opacity: 0.9;
    transition-duration: 150ms;
}

.bg-white {
    background-color: var(--main-bg-color);
    z-index: 999;
}

.rsc-seperator {
    grid-area: seperator;
    margin: 1% 20% 5% 20%;
}

.rsc-seperator .title {
    text-align: center;
    font-family: 'Marck Script', cursive;
    font-size: 90px !important;
    font-weight: 550;
    line-height: 60px;
    color: var(--main-blue);
    margin: 5% auto 0 auto;
    padding-bottom: 0px;
}

.rsc-seperator .text {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 20px !important;
    margin-top: 10Px;
}

.map-marker {
    font-size: 12px;
    display: grid;
    grid-template-areas: 'icon''text';
    align-items: center;
}

.map-marker .text {
    grid-area: text;
    font-size: 11px;
    font-weight: 500;
    max-width: 120px;
    text-align: center;
    text-shadow: 1px 1px 3px white !important;
}

.map-marker .icon {
    grid-area: icon;
    text-shadow: 1px 1px 3px white !important;
}

.map-marker .logo {
    grid-area: icon;
    width: 20px;
}

.map-container {
    width: 100%;
    height: 90vh;
    position: relative;
    color: black;
}

.linkbox-extern.link-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    text-align: center;
    background-color: var(--main-bg-color);
    opacity: 0.5;
    transition-duration: 300ms;
}

.linkbox-extern.link-button:hover {
    opacity: 1;
    transition-duration: 300ms;
}

.mapboxgl-ctrl-fullscreen {
    position: absolute;
    bottom: 20px;
    right: 0;
}

.home-text {
    background-color: var(--main-bg-color);
    padding: 100px 15px 40px 15px;
    text-align: center;
    color: var(--main-fg-color);
    font-size: 30px;
    width: 100%;
}

.textblock {
    background-color: var(--main-bg-color);
    padding: 80px 15px 80px 15px;
    grid-area: item;
    text-align: left;
    color: var(--main-fg-color);
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    border-bottom: 3px solid var(--main-blue);

}

.textblock ul {
    list-style-type: none;
}

.textblock li:before {
    content: "→";
}

.header {
    font-size: 40px;
    line-height: 50px;
    text-align: left;
    font-weight: 600;
    color: var(--main-green);
    margin-bottom: 10px;
}

.center {
    text-align: center;
    margin: auto;
}

.slide {
    max-height: 100%;
}

carousel-item {
    height: 100% !important;
}

.textblock .sub {
    font-weight: 600;
    margin-bottom: 10px;
}

.submenu-container {
    width: 100%;
    margin: 50px 0 20px 0;
    display: grid;
    grid-template-areas: 'sub-item sub-item sub-item';
}

.submenu-item {
    color: var(--main-green);
    padding: 2px;
}

.submenu-item .submenu-icon {
    font-size: 80px;
    text-align: center;
}

.submenu-item:hover .submenu-icon {
    color: var(--main-blue);
    transition-duration: 350ms;
}

.submenu-item .submenu-header {
    font-size: 30px;
    text-align: center;
}

.submenu-item:hover .submenu-header {
    color: var(--main-blue);
    transition-duration: 350ms;
}

.submenu-item .submenu-text {
    color: var(--main-fg-color);
    text-align: center;
    font-size: 19px;
}

.card-header {
    background-color: var(--main-bg-color);
    color: rgba(29, 102, 151, 0.849);
    font-size: 20px;
    font-weight: bold;
}

.kontakt-karte {
    padding: 10px;
    border: 1px solid var(--second-bg-color);
    ;
    width: 400px;
    margin: auto;
    display: grid;
    grid-template-areas: 'kontakt-image''kontakt-name''kontakt-position''kontaktzeile''kontaktzeile''kontaktzeile''kontaktzeile';
    grid-template-columns: 1fr;
}

.kontakt-image {
    grid-area: kontakt-image;
    margin: auto;
    width: 60%;
}

.kontakt-name {
    grid-area: kontakt-name;
    font-size: 22px;
    color: var(--main-blue);
    text-align: center;
    line-height: 26px;
    margin-bottom: 10px;
}

.kontakt-position {
    grid-area: kontakt-position;
    font-size: 18px;
    color: var(--main-green);
    text-align: center;
    line-height: 22px;
    margin-bottom: 10px;
}

.kontakt-zeile {
    grid-area: kontaktzeile;
    display: grid;
    grid-template-areas: 'kontakt-icon kontakt-text';
    line-height: 22px;
    grid-template-columns: 30px;
    padding-left: 15px;
    padding-bottom: 5px;
    overflow: hidden;
    max-width: 100%;
}

.kontakt-icon {
    grid-area: kontakt-icon;
    margin: auto;
    padding-left: 1px;
}

.kontakt-text {
    grid-area: kontakt-text;
}

.news-karte {
    border: 1px solid var(--second-bg-color);
    display: grid;
    grid-template-areas: 'news-zeile''news-image''news-title''news-text';
    grid-template-rows: 32px 300px max-content auto;
    grid-template-columns: 1fr;
    overflow: auto;
    -webkit-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    transition-duration: 1s;
    max-height: 600px;
    grid-auto-flow: row dense;
}

.news-karte::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: var(--main-bg-color);
    ;
    /* or add it to the track */
}

.news-karte::-webkit-scrollbar-thumb {
    background: rgba(7, 143, 77, 0.733);
    border-radius: 90px;
}

.news-image {
    grid-area: news-image;
    margin: 0 -10px 0 -10px;
    transition-duration: 500ms;
    width: 100%;
    max-height: 400px;
}

.news-image-container {
    height: 300px;
    overflow: hidden;
    transition-duration: 2s;
    text-align: center;
    box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
    -webkit-box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
    -moz-box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
}

.blog-post-image-container {
    width: 100%;
    max-height: 500px;
    border-radius: 10px;
    overflow: hidden;
    transition-duration: 2s;
    text-align: center;
    align-items: center;
    box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
    -webkit-box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
    -moz-box-shadow: 1px -19px 25px -30px var(--dark-shadow-color) inset;
}


.blog-post-title {
    padding-bottom: 15px;
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 500;
}

.blog-post-social-share {
    padding-bottom: 10px;
}

.blog-post-stand {
    padding-bottom: 15px;
    font-style: italic;
}

.blog-post-text {
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.6rem;
}

.carousel-control-next-icon {
    display: hidden !important;
}

.carousel-control-prev-icon {
    display: hidden !important;
}

.news-title {
    grid-area: news-title;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
    color: var(--main-fg-color);
    padding: 2px 10px 5px 10px;
}

.news-text {
    grid-area: news-text;
    border-bottom: 3px solid var(--main-bg-color);
    ;
    padding: 2px 10px 5px 10px;
    color: var(--second-fg-color);
}

.news-zeile {
    grid-area: news-zeile;
    display: grid;
    grid-template-areas: 'news-author news-date';
    grid-template-columns: 70% 30%;
    color: var(--main-fg-color);
    padding: 2px 10px 5px 10px;
    font-size: 16px;
    max-height: 31px;
}

.news-author {
    grid-area: news-author;
    text-align: left;
    max-height: 24px;
}

.news-date {
    grid-area: news-date;
    text-align: right;
    max-height: 24px;
}

.news-icon {
    margin-right: 5px;
    max-height: 24px;
}

.termine-container {
    grid-area: main;
    width: 70%;
    margin: 0 15% 5% 15%;
    min-height: 600px;
}

.termine-karte {
    grid-area: item;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgb(235, 235, 235);
    display: grid;
    grid-template-areas: 'termine-zeile termine-zeile''termine-image termine-title''termine-image termine-text''termine-image termine-ort';
    grid-template-rows: 30px 30px 80px 30px;
    grid-template-columns: 300px 1fr;
    overflow: auto;
    width: 100%;
    -webkit-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
}

.termine-image {
    margin: auto;
    transition-duration: 500ms;
    width: 100%;
}

.termine-image-container {
    grid-area: termine-image;
    overflow: hidden;
    transition-duration: 2s;
    text-align: center;
}

.termine-title {
    grid-area: termine-title;
    font-size: 26px;
    font-weight: 500;
    color: rgba(37, 109, 47, 0.794);
    margin-bottom: 10px;
    margin-top: 2px;
    padding-left: 5px;
    line-height: 26px;
}

.termine-text {
    grid-area: termine-text;
    padding-left: 5px;
    font-size: 18px;
}

.termine-zeile {
    grid-area: termine-zeile;
    display: grid;
    grid-template-areas: 'termine-date termine-eintritt';
    grid-template-columns: 70% 30%;
    color: var(--main-fg-color);
    padding: 2px 0 10px 0;
    font-size: 18px;
}

.termine-eintritt {
    grid-area: termine-eintritt;
    text-align: right;
    font-size: 18px;
    color: rgba(37, 109, 47, 0.794);
}

.termine-date {
    grid-area: termine-date;
    text-align: left;
    font-size: 18px;
    padding-bottom: 5px;
}

.termine-ort {
    grid-area: termine-ort;
    font-size: 20px;
    margin-top: 0px;
    padding-left: 15px;
    color: rgba(29, 102, 151, 0.850);
}

.termine-icon {
    margin-right: 5px;
}

.linkbox-extern {
    border: 1px solid rgba(128, 128, 128, 0.287);
    border-radius: 4px;
    text-decoration: none;
    margin: 10px 0 30px 0;
    font-size: 24px;
    padding: 15px;
    -webkit-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
}

.vereine-karte {
    grid-area: item;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--second-bg-color);
    display: grid;
    grid-template-areas: 'vereine-logo vereine-name vereine-name''vereine-logo anschrift vereine-ansprechpartner''vereine-text vereine-text vereine-text';
    grid-template-rows: 50px auto;
    grid-template-columns: 200px auto 1fr;
    grid-gap: 10px;
    overflow: auto;
    width: 100%;
    -webkit-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
    box-shadow: 0px 2px 8px 0px var(--light-shadow-color);
}

.vereine-logo {
    margin: auto;
    transition-duration: 500ms;
    width: 200px;
}

.vereine-logo-container {
    grid-area: vereine-logo;
    overflow: hidden;
    transition-duration: 2s;
    text-align: center;
}

.vereine-name {
    grid-area: vereine-name;
    font-size: 30px;
    font-weight: 500;
    color: var(--main-fg-color);
    margin-bottom: 10px;
    margin-top: 2px;
    padding-left: 5px;
    line-height: 26px;
}

.vereine-text {
    grid-area: vereine-text;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 2px;
    padding: 20px;
    line-height: 26px;
}

.vereine-anschrift {
    grid-area: anschrift;
    padding-left: 5px;
    padding-right: 50px;
    font-size: 20px;
}

.vereine-anschrift-item {
    display: grid;
    grid-template-areas: 'kontakt-icon item';
    grid-template-columns: 30px;
    grid-gap: 5px;
}

.vereine-ansprechpartner-wrapper {
    grid-area: vereine-ansprechpartner;
    display: grid;
    grid-template-areas: 'v-an-head''v-an';
    grid-template-columns: 100%;
    grid-template-rows: 35px auto;
    padding-left: 5px;
    font-size: 18px;
    right: 0;
    justify-self: right;
}

.vereine-ansprechpartner-header {
    grid-area: v-an-head;
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
}

.vereine-ansprechpartner {
    grid-area: v-an;
    padding-left: 5px;
    font-size: 18px;
    right: 0;
    justify-self: right;
}

a {
    text-decoration: none !important;
}

.vereine-to-page {
    grid-area: to-page;
    padding-left: 5px;
    font-size: 18px;
}

.link-icon {
    text-decoration: none;
    font-size: 32px;
}

.generic-image {
    margin: 20px;
    width: 50%;
    border-radius: 3px;
}

.social-link-wrapper {
    background-color: var(--main-green);
    color: #fff;
    display: grid;
    grid-area: social-links;
    grid-template-areas: 'header header''xcolumn xcolumn';
    grid-template-columns: auto auto;
    grid-template-rows: 50px auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 4%;
}

.social-link-header {
    grid-area: header;
    font-size: 35px;
    font-weight: 600;
}

.social-link {
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 50px;
}

.social-link:hover {
    color: rgba(255, 255, 255, 0.678);
    transition-duration: 300ms;
    text-align: center;
    padding: 10px;
    font-size: 50px;
}

.navbar-collapsed {
    display: none;
}

.mobile-menu-wrapper {
    grid-area: main;
    display: block;
}

.mobile-menu-wrapper ul {
    list-style-type: none;
}

.mobile-navbar-item {
    width: 100%;
}

.mobile-navbar-item .navbar-item-title {
    font-size: 40px !important;
}

.mobile-menu-wrapper ul li {
    color: var(--main-blue) !important;
    font-size: 30px;
    width: 100%;
}

.menu-divider:hover {
    background-color: var(--third-bg-color) !important;
}

.menu-divider {
    height: 1px;
    border-bottom: 2px solid var(--dark-shadow-color);
    background-color: var(--third-bg-color);
    margin: 0 0 5px 0;
}

.not-found-text {
    text-align: center;
    width: 100%;
    font-size: 45px;
    color: rgb(207, 207, 207);
    margin-top: 10%;
}

@keyframes fadeIn {
    0% {
        opacity: 0.25;
        visibility: visible;
    }

    40% {
        opacity: 1;
        visibility: visible;
    }

    90% {
        opacity: 0.25;
        visibility: visible;
    }

    100% {
        opacity: 0.25;
        visibility: visible;
    }
}

@keyframes einfliegen1 {
    0% {
        right: -300px;
        width: 0px;
        visibility: hidden;
    }

    89% {
        visibility: hidden;
    }

    90% {
        right: -50px;
        width: 0px;
        visibility: visible;
    }

    100% {
        right: 0px;
        width: 320px;
    }
}

@keyframes einfliegen2 {
    0% {
        right: -300px;
        width: 0px;
        visibility: hidden;
    }

    89% {
        visibility: hidden;
    }

    90% {
        right: -50px;
        width: 0px;
        visibility: visible;
    }

    100% {
        right: 0px;
        width: 230px;
    }
}

@media only screen and (max-width: 1600px) {
    .rsc-navbar {
        grid-template-columns: 30% 30% 40%;
        grid-template-areas: 'logo1 logo2 navmenu';
    }

    .rsc-navMenu {
        display: none;
        grid-area: unset;
        z-index: 990;
    }

    .navbar-collapsed {
        display: block;
        grid-area: navmenu;
    }

    .navbar-collapsed .fa-bars {
        color: var(--main-blue) !important;
        font-size: 50px;
        margin: 10px;
        padding: 5px;
        float: right;
    }

    .navbar-collapsed:hover>.rsc-navMenu {
        display: inherit !important;
        position: fixed;
        top: 100px;
        left: 0 !important;
        bottom: 0;
        margin: 0 !important;
        background-color: var(--main-bg-color);
        ;
    }

    .navbar-collapsed:hover>.navbar-item {
        width: inherit;
    }

    .rsc-content {
        grid-template-rows: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .rsc-appWrapperHome {
        display: grid;
        height: 100% !important;
        width: 100% !important;
        grid-template-rows: 80px auto 1fr auto auto;
        grid-template-areas: 'nav''seperator''main''footer';
        grid-template-columns: 1fr;
        overflow: auto;
    }

    .rsc-content {
        grid-area: main;
        display: grid;
        width: 100% !important;
        grid-template-columns: 100%;
        grid-template-areas: 'sep''item';
        grid-template-rows: auto;
        padding: 0;
        margin: 0;
    }

    .blog-post-container {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: auto;
    }
    .blog-post-image-container {
        border-radius: 0px 0px 10px 10px;
    }

    .blog-post-title {
        font-size: 30px;
        line-height: 34px;
        margin-top: 15px;
    }

    .blog-post-text {    
        font-size: 20px;
        max-height: none;
    }

    .rsc-slideshow {
        grid-area: slide;
        overflow: hidden;
        height: 0px !important;
    }

    .rsc-navbar .text-logo {
        font-size: 20px;
        height: 0;
    }

    .leeresPanel {
        margin: 2% 0 5% 0;
        padding: 0 !important;
        max-width: 100% !important;
    }
    .blogPanel {
        margin: 0 1% auto 1% !important;
        max-height: none;
        min-height: 100%;
    }
    .rsc-vorstand {
        margin: auto;
        padding: auto;
        grid-template-columns: 1fr;
    }

    .kontaktinfos {
        list-style-type: none;
        margin: -35px;
        margin-left: -50px;
        margin-right: -15px;
        margin-top: 10px;
        min-height: 200px !important;
    }

    .submenu-container {
        grid-template-areas: 'sub-item''sub-item''sub-item';
    }

    .navbar-toggler {
        margin-right: 5px;
        max-width: 60px;
        float: right;
    }

    .rsc-navbar .rsc-navItem {
        padding: 0 5px 0 5px;
        overflow-y: scroll !important;
    }

    .rsc-navbar .rsc-navItem-Title {
        font-size: 120%;
    }

    .navbar-brand img {
        width: 110px;
        height: auto;
    }

    .navbar-brand {
        height: auto !important;
    }

    .textblock {
        padding: 10px 10px 30px 10px;
        font-size: 16px;
    }

    .kontakt-karte {
        margin: auto;
        width: 300px;
    }

    .kontakt-name {
        font-size: 18px;
        line-height: 22px;
    }

    .kontakt-position {
        font-size: 14px;
        line-height: 18px;
    }

    .news-karte {
        width: 100%;
        border-left: none;
        border-right: none;
        max-height: none;
    }

    .news-zeile {
        font-size: 13px;
    }

    .news-text {
        height: auto;
    }

    .news-title {
        margin-top: 5px;
        padding-top: 0px;
    }

    .termine-title {
        padding-top: 5px;
    }

    .termine-container {
        width: 100% !important;
        grid-template-areas: 'item';
        padding: 0;
        margin: 0;
    }

    .termine-karte {
        grid-area: item;
        padding: 10px;
        border: 1px solid rgb(235, 235, 235);
        display: grid;
        grid-template-areas: 'termine-zeile''termine-image''termine-title''termine-text''termine-ort';
        grid-template-rows: 35px auto 35px auto auto;
        grid-template-columns: 1fr;
    }

    .termine-ort {
        font-size: 20px;
        padding-left: 5px;
        padding-top: 5px;
    }

    .social-link-wrapper {
        margin-top: 4%;
        padding-top: 5%;
    }

    .social-link {
        font-size: 40px;
    }

    .social-link-header {
        font-size: 25px;
    }

    .rsc-footer {
        display: grid;
        grid-template-areas: 'xcolumn';
        grid-template-columns: 100%;
        justify-content: center;
        padding: 2% 5% 5% 5%;
    }

    .rsc-footer-item {
        font-size: 16px !important;
    }

    .rsc-footer-column {
        margin: 5px;
        padding: 5px;
    }

    .rsc-footer-column:nth-child(2) {
        border-top: 3px solid rgb(53, 52, 52);
        border-bottom: 3px solid rgb(53, 52, 52);
        border-left: none;
        border-right: none;
    }

    .butterCMS {
        position: inherit;
        text-align: center;
    }

    .butterCMS img {
        width: 50%;
    }

    .rsc-seperator {
        margin: 3% 0 4% 0;
        font-size: 20px !important;
    }

    .rsc-seperator .title {
        font-size: 50px !important;
    }

    .home-text {
        font-size: 20px;
        margin: 0;
        padding: 10px 0 30px 0;
    }

    .generic-image {
        width: 80%;
    }

    .vereine-karte {
        grid-area: item;
        grid-template-areas: 'vereine-logo vereine-name''vereine-logo anschrift''vereine-ansprechpartner vereine-ansprechpartner''vereine-text vereine-text''to-page to-page';
        grid-template-rows: auto auto auto auto auto;
        grid-template-columns: 100px 1fr;
        grid-gap: 10px;
        width: 100% !important;
        max-width: 100% !important;
    }

    .vereine-logo {
        width: 100px;
    }

    .vereine-name {
        font-size: 24px;
    }

    .vereine-anschrift {
        font-size: 14px;
        margin-right: 0;
        padding-right: 0;
    }

    .vereine-text {
        font-size: 16px;
    }

    .vereine-ansprechpartner {
        grid-column: 1 / 3 !important;
        font-size: 14px;
    }

    .navbar-collapse {
        overflow-y: scroll !important;
        max-height: 1fr !important;
    }

    .dropdown-menu {
        overflow-y: scroll !important;
        max-height: 1fr !important;
    }

    .show {
        overflow-y: scroll !important;
        max-height: 1fr !important;
    }

    .header {
        font-size: 30px;
    }

    h1 {
        font-size: 25px !important;
    }

    .sep {
        grid-column: 1;
        grid-row: 1;
        font-size: 40px !important;
    }
}